<template>
  <main id="main">
    <section>
      <div class="container">
        <h1
          class="text-center"
          style="
            /*text-transform: uppercase;*/
            font-family: Montserrat, sans-serif;
            font-weight: bold;
          "
        >
          Coin Listing Form (Instant)
        </h1>
        <form
          id="listForm"
          method="post"
        >
          <div
            class="d-flex justify-content-center"
            style="margin-bottom: 30px"
          >
            <div
              style="
                background: rgba(0, 0, 0, 0.12);
                border: 3px solid rgba(255, 255, 255, 0.2);
                border-radius: 6px;
              "
            >
              <label
                class="form-label d-flex justify-content-center"
                style="
                  margin-bottom: 2px;
                  padding: 1px 5px;
                  background: rgba(0, 0, 0, 0.15);
                  color: rgb(180, 180, 180);
                  font-size: 18px;
                  font-weight: bold;
                "
                ><i class="far fa-image d-flex align-items-center"></i
                >&nbsp;Coin Icon *</label
              >
              <div
                class="d-flex justify-content-center"
                style="padding: 5px 20px"
              >
                <div id="logo-input">
                  <input
                    class="form-control"
                    type="file"
                    style="width: 100%; height: 100%; border: 1px solid"
                    accept="image/png, image/jpeg"
                    required=""
                    name="icon"
                  />
                  <div
                    class="d-flex justify-content-center align-items-center"
                    style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      pointer-events: none; /*background: repeating-linear-gradient(-44deg, black, rgb(21,21,21) 13%);*/
                      background: rgb(25, 29, 33);
                    "
                  >
                    <!-- <span class="text-center"
                      ><i class="fas fa-cloud-upload-alt"></i
                      >&nbsp;Upload<br />(max 150kb) 256x256</span
                    > -->
                  </div>
                  <img
                    class="rounded-circle"
                    style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      pointer-events: none;
                      border: 6px solid rgb(72, 74, 78);
                      background: rgb(189, 189, 189);
                      display: none;
                    "
                    src="#"
                    onerror="this.style.display='none'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2">
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Coin Name*</legend>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="Bitcoin"
                  minlength="2"
                  required=""
                  name="name"
                  maxlength="50"
                  pattern="^[a-zA-Z0-9 .]+$"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Coin Symbol*</legend>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="BTC"
                  style="text-transform: uppercase"
                  required=""
                  minlength="2"
                  name="symbol"
                  pattern="^[a-zA-Z0-9]+$"
                />
              </fieldset>
            </div>
          </div>
          <fieldset class="input-fieldset">
            <legend class="float-none">Network/Chain*</legend>
            <div class="select">
              <select
                class="form-select form-select-lg"
                required=""
                name="chain"
              >
                <option value="bsc" selected="">
                  Binance Smart Chain (BSC)
                </option>
                <optgroup label="More coming soon..."></optgroup>
              </select>
            </div>
          </fieldset>
          <div class="row row-cols-1 row-cols-sm-2">
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Listing Status*</legend>
                <div class="select">
                  <select
                    class="form-select form-select-lg"
                    required=""
                    name="list_status"
                  >
                    <option value="listed" selected="">Listed</option>
                    <option value="presale">Presale</option>
                  </select>
                </div>
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Launch Date*</legend>
                <input
                  class="form-control form-control-lg"
                  type="date"
                  required=""
                  style="cursor: pointer"
                  placeholder="mm/dd/yyyy"
                  name="launch_date"
                />
              </fieldset>
            </div>
          </div>
          <fieldset class="input-fieldset">
            <legend class="float-none">Contract Address*</legend>
            <input
              class="form-control form-control-lg"
              type="text"
              placeholder="0xa9Aa41d4F6a6F4..."
              minlength="26"
              maxlength="42"
              required=""
              name="address"
              pattern="^[a-zA-Z0-9]+$"
            />
          </fieldset>
          <div style="margin-bottom: 30px">
            <fieldset class="input-fieldset" style="margin-bottom: 0px">
              <legend class="float-none">Coin Description*</legend>
              <textarea
                class="form-control"
                rows="5"
                placeholder="What makes the coin different from others? What is the goal/purpose of the coin?"
                style="padding-top: 10px"
                spellcheck="true"
                required=""
                minlength="100"
                maxlength="1500"
                name="description"
              ></textarea>
            </fieldset>
            <span
              class="d-flex justify-content-end"
              style="color: rgb(220, 220, 220)"
              >Min: 100 | Max: 1500</span
            >
          </div>
          <div
            class="
              row row-cols-md-3 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3
              justify-content-center
              social-inputs
            "
            style="margin-bottom: 10px"
          >
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Website Link*</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="watcher.guru"
                  minlength="2"
                  required=""
                  name="socials_website"
                  inputmode="url"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Telegram Link*</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="t.me/watcherguru"
                  required=""
                  minlength="3"
                  name="socials_telegram"
                  inputmode="url"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Chart Link</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="poocoin.app/tokens/0x..."
                  name="socials_chart"
                  inputmode="url"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Reddit Link</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="reddit.com/r/watcherguru"
                  name="socials_reddit"
                  inputmode="url"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Twitter Link</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="twitter.com/watcherguru"
                  name="socials_twitter"
                  inputmode="url"
                />
              </fieldset>
            </div>
            <div class="col">
              <fieldset class="input-fieldset">
                <legend class="float-none">Discord Link</legend>
                <input
                  class="form-control"
                  type="text"
                  placeholder="discord.gg/watcherguru"
                  name="socials_discord"
                  inputmode="url"
                />
              </fieldset>
            </div>
          </div>
          <div class="d-flex justify-content-center" style="margin-top: 20px">
            <button class="btn btn-primary" id="submitButton" type="submit">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                style="display: none"
              ></span
              ><span>Submit Listing</span>
            </button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>

<style scoped>
#listForm .input-fieldset select {
    background: 0 0;
    border: none;
    color: #e6e6e6;
    padding-top: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
#listForm .input-fieldset select {
    background: 0 0;
    border: none;
    color: #e6e6e6;
    padding-top: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}
select[data-v-49d72709] {
    text-shadow: none;
    text-align: start;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    cursor: default;
    margin: 0em;
    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-radius: 0px;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select {
    word-wrap: normal;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}

select:not(:-internal-list-box) {
    overflow: visible !important;
}

select {
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    box-sizing: border-box;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    cursor: default;
    margin: 0em;
    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    border-radius: 0px;
}
#listForm {
    background: rgba(73,101,143,.1);
    padding: 25px 30px!important;
    border-radius: 5px;
    font-family: Karla;
    margin: auto;
    max-width: 650px;
    box-shadow: 0 2px 10px 3px rgb(20 20 20)!important;
}
body {
    background-color: #181a1b;
    color: #fff;
    height: 100%;
    overflow-x: hidden;
    width: 100vw!important;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
:root {
    --swiper-navigation-size: 44px;
}
:root {
    --swiper-theme-color: #007aff;
}
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13,110,253;
    --bs-secondary-rgb: 108,117,125;
    --bs-success-rgb: 25,135,84;
    --bs-info-rgb: 13,202,240;
    --bs-warning-rgb: 255,193,7;
    --bs-danger-rgb: 220,53,69;
    --bs-light-rgb: 248,249,250;
    --bs-dark-rgb: 33,37,41;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-rgb: 33,37,41;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}
*, ::after, ::before {
    box-sizing: border-box;
}
*, ::after, ::before {
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

</style>